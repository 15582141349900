import { Link } from "react-router-dom";

const Footer = () => (
  <>
    <footer
      style={{ backgroundColor: "#0E121B" }}
      className="bg-gray-800"
      aria-labelledby="footerHeading"
    >
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Company
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link to="/about">
                      <p className="text-base text-gray-300 hover:text-white">
                        About us
                      </p>
                    </Link>
                  </li>

                  <li>
                    <Link to="/instruments">
                      <p className="text-base text-gray-300 hover:text-white">
                        Instruments
                      </p>
                    </Link>
                  </li>

                  <li>
                    <Link to="/faq">
                      <p className="text-base text-gray-300 hover:text-white">
                        FAQs
                      </p>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Trade
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <p className="text-base text-gray-300 hover:text-white">
                      <a href="https://app.fxhorizon.live">NFTs</a>
                    </p>
                  </li>

                  <li>
                    <p className="text-base text-gray-300 hover:text-white">
                      <a href="https://app.fxhorizon.live">Crypto</a>
                    </p>
                  </li>

                  <li>
                    <p className="text-base text-gray-300 hover:text-white">
                      <a href="https://app.fxhorizon.live">Stocks</a>
                    </p>
                  </li>

                  <li>
                    <p className="text-base text-gray-300 hover:text-white">
                      <a href="https://app.fxhorizon.live">Forex</a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Solutions
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link to="/ib">
                      <p className="text-base text-gray-300 hover:text-white">
                        Become an IB
                      </p>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Legal
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link to="/privacy">
                      <p className="text-base text-gray-300 hover:text-white">
                        Privacy
                      </p>
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  support
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <a
                      href="mailto:support@fxhorizon.live"
                      className="text-base text-gray-300 hover:text-white font-bold"
                    >
                      Send us an email
                    </a>
                  </li>

                  <li>
                    <a
                      href="/"
                      className="text-base text-gray-300 hover:text-white"
                    >
                      124 City Rd, London EC1V 2NX, United Kingdom.
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-8 xl:mt-0">
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              DISCLOSURE
            </h3>
            <p className="mt-4 text-base text-gray-300">
              FxHorizon is owned and operated by Global Capital Securities and
              Commodities Limited.
              <br />
              <br />
              We are Regulated by Vanuatu Financial Services Commissions (VFSC)
              and Authorized to deal with securities with providing all kind of
              financial services to our clients. Registration number - 40491
              with registered address at 124 City Rd, London EC1V 2NX, United
              Kingdom.
            </p>
            <br />
            <p className="mt-4 text-base text-gray-300">
              Risk Warning: Your Capital is not at risk. Our products are
              suitable for non-experienced traders and our investment is
              available in any country.
            </p>
          </div>
        </div>
        <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
          © FxHorizon, Inc. All rights reserved.
        </p>
      </div>
    </footer>
  </>
);

export default Footer;
