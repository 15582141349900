import React from "react";

const Text = ({ head, desc }) => {
  return (
    <h1>
      <span
        style={{ color: "#1199FA" }}
        className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase"
      >
        {head}
      </span>
      <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
        {desc}
      </span>
    </h1>
  );
};

export default Text;
