import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
import Slider from "react-slick";
import useMediaQuery from '@mui/material/useMediaQuery';
import Text from "./Text";

const Testimonials = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const tablet = useMediaQuery('(max-width:1024px)'); 
    const mobile = useMediaQuery('(max-width:900px)');

   const reviews = [
    {
        name: "Sir Danthel",
        image: "https://user-images.trustpilot.com/62f64d015f9959001469c9f1/73x73.png",
        title: "Trader",
        review: "At this point I'm just in awe at this platform. From the emails keeping in touch and even the guidance. What a wonderful opportunity to help my family and become the earner I know I can be. 5 stars"
    },
    {
        name: "Louis can Nuekerk",
        image: "https://user-images.trustpilot.com/62fb7908e7527700131ee873/73x73.png",
        title: "User",
        review: "Incredible company, I've been trading for a while with them, had two quick withrawals and fee refund in less than 48 hours. I absolutely recommend the firm"
    },
    {
        name: "Peter M",
        image: "https://user-images.trustpilot.com/630399601be97400121ac68d/73x73.png",
        title: "User",
        review: "This is an amazing platform which gives you opportunity to achieve their goals and become successful trade with the support of finances."
    },
    {
        name: "Jakub Szulc",
        image: "https://user-images.trustpilot.com/6304aa40cc230c001374b256/73x73.png",
        title: "Trader",
        review: "Great company, no issues with payouts, great customer support. Highly recommend for experienced traders."
    },
    {
        name: "Jeremy Harper",
        image: "https://user-images.trustpilot.com/62f7f8c90a1282001276670b/73x73.png",
        title: "Trader",
        review: "Legitimate! Thank you for the opportunity, nothing out there that can compare"
    },
   ]
    
      const settingsMobile = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        customPaging: (i) => (
          <div
            style={{
              width: "calc(100vw / 50)",
              color: "blue",
              position: "relative",
              placeSelf: "center"
            }}
          >
            <div style={{ opacity: "0", cursor: "pointer" }}>{i}</div>
            <span
              style={{
                position: "absolute",
                right: "300%",
                cursor: "pointer",
                backgroundColor: i === currentSlide ? "#0781fe" : "#343638",
                height: "15px",
                width: "15px",
                borderRadius: "100%",
              }}
            ></span>
          </div>
        ),
      };
    

       
      const settingsDesktop = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        customPaging: (i) => (
          <div
            style={{
              width: "5vw",
              color: "blue",
              position: "relative",
            }}
          >
            <div style={{ opacity: "0", cursor: "pointer" }}>{i}</div>
            <span
              style={{
                position: "absolute",
                right: "150px",
                cursor: "pointer",
                backgroundColor: i === currentSlide ? "#0781fe" : "#343638",
                height: "15px",
                width: "15px",
                borderRadius: "100%",
              }}
            ></span>
          </div>
        ),
      };

  return (
    <div>

<section className="bg-[#0e121b] px-10 py-20 items-center mt-5">
    {/* <div className="max-w-7xl mx-auto grid items-center"> */}

    <Text
          head="Testimonials"
          desc="Reviews from our amazing users"
        />

  {!tablet &&  <Slider {...settingsDesktop} afterChange={(e) => setCurrentSlide(e)} className='px-20 py-20'>

        {reviews.map(review => (
    // <div className="py-12 px-10 sm:px-10 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-indigo-900 lg:pr-16">
   
    //   </div>
<div>
    <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
    <div className="relative text-lg font-medium text-white md:flex-grow">
      <svg className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-indigo-600" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"></path>
      </svg>
      <p className="relative">
        {review.review}
      </p>
    </div>
    <footer className="mt-8">
      <div className="flex items-start">
        <div className="flex-shrink-0 inline-flex rounded-full border-2 border-white">
          <img className="h-12 w-12 rounded-full" src={review.image} alt="" />
        </div>
        <div className="ml-4">
          <div className="text-base font-medium text-white">{review.name}</div>
          <div className="text-base font-medium text-indigo-200">{review.title}</div>
        </div>
      </div>
    </footer>
  </blockquote>

  </div>

))}
    </Slider>}

    {tablet &&  <Slider {...settingsMobile} afterChange={(e) => setCurrentSlide(e)} className='px-5 py-20'>

{reviews.map(review => (
// <div className="py-12 px-10 sm:px-10 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-indigo-900 lg:pr-16">

//   </div>
<div>
<blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
<div className="relative text-lg font-medium text-white md:flex-grow">
<svg className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-indigo-600" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
<path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"></path>
</svg>
<p className="relative">
{review.review}
</p>
</div>
<footer className="mt-8">
<div className="flex items-start">
<div className="flex-shrink-0 inline-flex rounded-full border-2 border-white">
  <img className="h-12 w-12 rounded-full" src={review.image} alt="" />
</div>
<div className="ml-4">
  <div className="text-base font-medium text-white">{review.name}</div>
  <div className="text-base font-medium text-indigo-200">{review.title}</div>
</div>
</div>
</footer>
</blockquote>

</div>

))}
</Slider>}


   
      {/* 
      <div className="py-12 px-4 border-t-2 border-indigo-900 sm:px-6 md:py-16 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16">
        <div className="md:flex-shrink-0">
          <img className="h-12" src="https://tailwindui.com/img/logos/workcation-logo-indigo-300.svg" alt="Workcation" />
        </div>
        <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
          <div className="relative text-lg font-medium text-white md:flex-grow">
            <svg className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-indigo-600" fill="currentColor" viewBox="0 0 32 32">
              <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"></path>
            </svg>
            <p className="relative">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo expedita voluptas culpa sapiente alias molestiae. Numquam corrupti in laborum sed rerum et corporis. Nemo expedita voluptas culpa sapiente alias molestiae.
            </p>
          </div>
          <footer className="mt-8">
            <div className="flex items-start">
              <div className="flex-shrink-0 inline-flex rounded-full border-2 border-white">
                <img className="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80" alt="" />
              </div>
              <div className="ml-4">
                <div className="text-base font-medium text-white">Joseph Rodriguez</div>
                <div className="text-base font-medium text-indigo-200">CEO, Workcation</div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
      <div className="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-indigo-900 lg:pr-16">
        <div className="md:flex-shrink-0">
          <img className="h-12" src="https://tailwindui.com/img/logos/tuple-logo-indigo-300.svg" alt="Tuple" />
        </div>
        <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
          <div className="relative text-lg font-medium text-white md:flex-grow">
            <svg className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-indigo-600" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
              <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"></path>
            </svg>
            <p className="relative">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo expedita voluptas culpa sapiente alias molestiae. Numquam corrupti in laborum sed rerum et corporis.
            </p>
          </div>
          <footer className="mt-8">
            <div className="flex items-start">
              <div className="flex-shrink-0 inline-flex rounded-full border-2 border-white">
                <img className="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80" alt="" />
              </div>
              <div className="ml-4">
                <div className="text-base font-medium text-white">Judith Black</div>
                <div className="text-base font-medium text-indigo-200">CEO, Tuple</div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div> */}
    {/* </div> */}
  </section>
        
    </div>
  )
}

export default Testimonials