const ImageWithStats = () => {
  return (
    <>
      <div _style={{ maxHeight: "800px" }}>
        <div
          style={{ backgroundColor: "#0E121B" }}
          className="relative py-16 sm:py-24"
        >
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
            <div className="relative sm:py-16 lg:py-0">
              <div
                aria-hidden="true"
                className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
              >
                {/* <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72"></div> */}
                {/* <svg
                  className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                  width="404"
                  height="392"
                  fill="none"
                  viewBox="0 0 404 392"
                >
                  <defs>
                    <pattern
                      id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        className="text-gray-200"
                        fill="currentColor"
                      ></rect>
                    </pattern>
                  </defs>
                  <rect
                    width="404"
                    height="392"
                    fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
                  ></rect>
                </svg> */}
              </div>
              <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                  <img
                    className="absolute inset-0 h-full w-full object-cover"
                    src="https://images.unsplash.com/photo-1521510895919-46920266ddb3?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;fp-x=0.5&amp;fp-y=0.6&amp;fp-z=3&amp;width=1440&amp;height=1440&amp;sat=-100"
                    alt=""
                  />
                  <div
                    className="absolute inset-0 bg-blue-500"
                    style={{ mixBlendMode: "multiply" }}
                  ></div>
                  <div className="absolute inset-0 bg-gradient-to-t from-blue-600 via-blue-600 opacity-90"></div>
                  {/* <div className="relative px-8"> */}
                  {/* <blockquote className="mt-8">
                      <div className="relative text-lg font-medium text-white md:flex-grow">
                        <svg
                          className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-blue-400"
                          fill="currentColor"
                          viewBox="0 0 32 32"
                          aria-hidden="true"
                        >
                          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"></path>
                        </svg>
                        <p className="relative">
                          I used to be really confused about
                        </p>
                      </div> */}

                  {/* <footer className="mt-4">
                        <p className="text-base font-semibold text-blue-200">
                          Sophie, Professional Trader.
                        </p>
                      </footer> */}
                  {/* </blockquote> */}
                  {/* </div> */}
                </div>
              </div>
            </div>

            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
              {/* Content area */}
              <div className="pt-12 sm:pt-16 lg:pt-20">
                <h2
                  style={{ color: "#1199FA" }}
                  className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl"
                >
                  The revolution of your income. Let’s grow together! No tricks,
                  just profit.
                </h2>
                <div
                  style={{ color: "#c9c9c9" }}
                  className="mt-6 text-gray-500 space-y-6"
                >
                  <p className="text-lg">
                    Our unique FxHorizon IB Portal designed to give you the
                    transparent dashboard of your income, client activity and
                    leads generated.
                  </p>
                  <p className="text-base leading-7">
                    We also provide an IB link to share with your clients
                    through multiple ways. Access for all your live account
                    holders with real-time margins and commissions.
                  </p>
                  <p className="text-base leading-7">
                    We give you a Marketing Kit that comes in a variety of
                    languages. Banners is just a small part of it.
                  </p>
                </div>
              </div>

              {/* Stats section */}
              <div className="mt-10">
                <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                  <div className="border-t-2 border-gray-100 pt-6">
                    <dt
                      style={{ color: "#1199FA" }}
                      // style={{ backgroundColor: ",
                      className="text-base font-medium text-gray-500"
                    >
                      Founded
                    </dt>
                    <dd
                      style={{ color: "white" }}
                      className="text-3xl font-extrabold tracking-tight text-gray-900"
                    >
                      2012
                    </dd>
                  </div>

                  <div className="border-t-2 border-gray-100 pt-6">
                    <dt
                      style={{ color: "#1199FA" }}
                      className="text-base font-medium text-gray-500"
                    >
                      Employees
                    </dt>
                    <dd
                      style={{ color: "white" }}
                      className="text-3xl font-extrabold tracking-tight text-gray-900"
                    >
                      25
                    </dd>
                  </div>
                  <div className="border-t-2 border-gray-100 pt-6">
                    <dt
                      style={{ color: "#1199FA" }}
                      className="text-base font-medium text-gray-500"
                    >
                      Investors
                    </dt>
                    <dd
                      style={{ color: "white" }}
                      className="text-3xl font-extrabold tracking-tight text-gray-900"
                    >
                      5,211
                    </dd>
                  </div>

                  <div className="border-t-2 border-gray-100 pt-6">
                    <dt
                      style={{ color: "#1199FA" }}
                      className="text-base font-medium text-gray-500"
                    >
                      Payouts
                    </dt>
                    <dd
                      style={{ color: "white" }}
                      className="text-3xl font-extrabold tracking-tight text-gray-900"
                    >
                      $3M
                    </dd>
                  </div>
                </dl>
                <div className="mt-10">
                  <a
                    href="https://app.fxhorizon.live"
                    style={{ color: "#1199FA" }}
                    className="text-base font-medium text-blue-600"
                  >
                    Get started with us today
                    <span aria-hidden="true">→</span>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageWithStats;
