import Text from "./Text";

const Subscriptions = () => (
  <>
    <div className="bg-white" style={{ backgroundColor: "#0E121B" }}>
      <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <Text
          head="Subscriptions"
          desc="Earn with ease using our subscriptions"
        />
        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
          <div
            style={{ backgroundColor: "#161C2A" }}
            className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
          >
            <div className="p-6">
              <h2
                style={{ color: "#1199FA" }}
                className="text-lg leading-6 font-medium text-gray-900"
              >
                Lite
              </h2>
              <p className="mt-4 text-sm text-white">
                Simple pricing to get you started quickly.
              </p>
              <p className="mt-8">
                <span className="text-4xl font-extrabold text-white">
                  $5,000
                </span>
                <span
                  style={{ color: "#1199FA" }}
                  className="text-base font-medium text-gray-500"
                >
                  min.
                </span>
              </p>
              <p
                style={{ backgroundColor: "#1199FA" }}
                className="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
              >
                <a href="https://app.fxhorizon.live">Choose Lite</a>
              </p>
            </div>
            <div className="pt-6 pb-8 px-6">
              <h3
                style={{ color: "#1199FA" }}
                className="text-xs font-medium text-gray-900 tracking-wide uppercase"
              >
                What's included
              </h3>
              <ul className="mt-6 space-y-4">
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 h-5 w-5 text-green-500"
                    data-todo-x-description="Heroicon name: solid/check"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-sm text-white">
                    Plan runs for 3 days
                  </span>
                </li>

                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 h-5 w-5 text-green-500"
                    data-todo-x-description="Heroicon name: solid/check"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-sm text-white">200% ROI</span>
                </li>

                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 h-5 w-5 text-green-500"
                    data-todo-x-description="Heroicon name: solid/check"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-sm text-white">
                    The maximum for this plan is $10,000
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div
            style={{ backgroundColor: "#161C2A" }}
            className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
          >
            <div className="p-6">
              <h2
                style={{ color: "#1199FA" }}
                className="text-lg leading-6 font-medium"
              >
                Starter
              </h2>
              <p className="mt-4 text-sm text-white">Earn more with us.</p>
              <p className="mt-8">
                <span className="text-4xl font-extrabold text-white">
                  $11,000
                </span>
                <span
                  style={{ color: "#1199FA" }}
                  className="text-base font-medium text-gray-500"
                >
                  min
                </span>
              </p>
              <a
                href="https://app.fxhorizon.live"
                style={{ backgroundColor: "#1199FA" }}
                className="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
              >
                Choose Starter
              </a>
            </div>
            <div className="pt-6 pb-8 px-6">
              <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                What's included
              </h3>
              <ul className="mt-6 space-y-4">
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 h-5 w-5 text-green-500"
                    data-todo-x-description="Heroicon name: solid/check"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-sm text-white">
                    Plan runs for 3 days
                  </span>
                </li>

                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 h-5 w-5 text-green-500"
                    data-todo-x-description="Heroicon name: solid/check"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-sm text-white">300% ROI</span>
                </li>

                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 h-5 w-5 text-green-500"
                    data-todo-x-description="Heroicon name: solid/check"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-sm text-white">
                    The maximum for this plan is $99,500
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div
            style={{ backgroundColor: "#161C2A" }}
            className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
          >
            <div className="p-6">
              <h2
                className="text-lg leading-6 font-medium text-white"
                style={{ color: "#1199FA" }}
              >
                Pro
              </h2>
              <p className="mt-4 text-sm text-white">
                Significant earnings with no additional time.
              </p>
              <p className="mt-8">
                <span className="text-4xl font-extrabold text-white">
                  $100,000
                </span>
                <span
                  style={{ color: "#1199FA" }}
                  className="text-base font-medium text-gray-500"
                >
                  min
                </span>
              </p>
              <p
                style={{ backgroundColor: "#1199FA" }}
                className="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
              >
                <a href="https://app.fxhorizon.live">Choose Pro</a>
              </p>
            </div>
            <div className="pt-6 pb-8 px-6">
              <h3
                style={{ color: "#1199FA" }}
                className="text-xs font-medium text-gray-900 tracking-wide uppercase"
              >
                What's included
              </h3>
              <ul className="mt-6 space-y-4">
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 h-5 w-5 text-green-500"
                    data-todo-x-description="Heroicon name: solid/check"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-sm text-white">
                    Plan runs for three days
                  </span>
                </li>

                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 h-5 w-5 text-green-500"
                    data-todo-x-description="Heroicon name: solid/check"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-sm text-white">403% ROI</span>
                </li>

                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 h-5 w-5 text-green-500"
                    data-todo-x-description="Heroicon name: solid/check"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-sm text-white">
                    The maximum for this plan is $1,000,000
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Subscriptions;
