import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./About";
import Main from "./Main";
import FAQ from "./FAQ";
import "./App.css";
import TradingInstruments from "./TradingInstruments";
import BecomeAnIB from "./BecomeAnIB";
import PrivacyPolicy from "./PrivacyPolicy";
import ScrollToTop from "./ScrollToTop";

const App = () => {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/about" element={<About />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/ib" element={<BecomeAnIB />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/instruments" element={<TradingInstruments />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
