const Branded = () => (
  <>
    <div className="relative">
      <div className="absolute inset-0">
        <img
          className="w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1920&amp;q=60&amp;&amp;sat=-100"
          alt=""
        />
        <div
          // style={{  }}
          className="absolute inset-0 bg-indigo-800"
          style={{ mixBlendMode: "multiply", backgroundColor: "#161C2A" }}
          aria-hidden="true"
        ></div>
      </div>
      <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h1
          style={{ color: "#1199FA" }}
          className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl"
        >
          Join Our Team
        </h1>
        <p className="mt-6 text-xl text-indigo-100 max-w-3xl">
          We work in one of the most dynamic and fluid industries in the world.
          As a result, we need to constantly adapt and evolve. At FxHorizon,
          we believe that there’s nothing better than working with the best and
          brightest minds, so we’re always on the lookout for talented and
          industrious people to join our growing team.
        </p>
      </div>
    </div>
  </>
);

export default Branded;
